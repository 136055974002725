import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import CatalogCeramic from "../../components/CatalogCeramic"

import {
  ceramicCars,
  ceramicCars2,
  ceramicCars3,
  ceramicCars4,
} from "../../components/CatalogCeramic/data"
import Pagination from "../../components/CatalogCeramic/Pagination"

const CatalogCeramicPage = props => {
  const { location } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState(ceramicCars)

  useEffect(() => {
    switch (currentPage) {
      case 1:
        setData(ceramicCars)
        break
      case 2:
        setData(ceramicCars2)
        break
      case 3:
        setData(ceramicCars3)
        break
      case 4:
        setData(ceramicCars4)
        break
      default:
        console.log("Неправильный номер страницы")
    }
    window.scrollTo(0, 0)
  }, [currentPage])

  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Цены на керамические катализаторы"
          description="Каталог керамических катализаторов, которые мы выкупили у частных клиентов. 
          Цены на них и данные по автомобилю, являющегося донором катализатора."
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <CatalogCeramic data={data}>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </CatalogCeramic>
      </div>
    </Layout>
  )
}

export default CatalogCeramicPage
