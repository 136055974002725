import React, { Fragment, useState } from "react"
import { navigate } from "gatsby"

import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"

import { classNames, convertToSlug, DEFAULT_BRAND_TEXT } from "../helpers"
import { brands } from "../Home/BrandsSection/brands"

export default function BrandSelect() {
  const [selected, setSelected] = useState(DEFAULT_BRAND_TEXT)

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div>
          <Listbox.Label className="sr-only block text-sm font-medium text-white">
            Марка
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:w-80 sm:text-sm">
              <span className="flex items-center">
                <selected.icon className="h-6 w-6 flex-shrink-0 rounded-full" />

                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-80 sm:text-sm">
                {brands.map(brand => (
                  <Listbox.Option
                    key={brand.filter}
                    onClick={() =>
                      navigate(`/prices/${convertToSlug(brand.name)}`)
                    }
                    className={({ active }) =>
                      classNames(
                        active ? "bg-corp-orange text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={brand}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <brand.icon className="h-6 w-6 flex-shrink-0 rounded-full" />
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {brand.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-corp-orange",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
