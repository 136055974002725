import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"

function Pagination({ currentPage, setCurrentPage }) {
  return (
    <>
      <div>
        <nav
          className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            href="#"
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={() => setCurrentPage(1)}
            className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium ${
              currentPage === 1
                ? "z-10 border-orange-500 bg-orange-50 text-orange-600"
                : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
            }`}
          >
            1
          </button>
          <button
            onClick={() => setCurrentPage(2)}
            className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium ${
              currentPage === 2
                ? "z-10 border-orange-500 bg-orange-50 text-orange-600"
                : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
            }`}
          >
            2
          </button>
          <button
            onClick={() => setCurrentPage(3)}
            className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium ${
              currentPage === 3
                ? "z-10 border-orange-500 bg-orange-50 text-orange-600"
                : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
            }`}
          >
            3
          </button>
          <button
            onClick={() => setCurrentPage(4)}
            className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium ${
              currentPage === 4
                ? "z-10 border-orange-500 bg-orange-50 text-orange-600"
                : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
            }`}
          >
            4
          </button>
          <button
            href="#"
            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </>
  )
}

export default Pagination
