import React from "react"
import { Link } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"

import PriceCard from "./PriceCard"
import BrandSelect from "./BrandSelect"

function CatalogCeramic({ data, children }) {
  return (
    <section
      id="ceramic"
      aria-labelledby="ceramic-heading"
      className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
    >
      <div className="mx-auto max-w-full md:max-w-7xl">
        <h2 className="text-center text-xl font-bold text-gray-900 sm:text-3xl">
          Внутренняя база катализаторов с XRF анализом
        </h2>
        <h3 className="px-6 text-center text-base font-light md:px-12 md:text-xl">
          Данные о стоимости катализатора получены после отдельного помола в
          собственном цеху с использованием стендового прибора Niton
        </h3>
        <BrandSelect />
      </div>

      <h1
        id="ceramic-heading"
        className="pt-2 text-center text-4xl font-extrabold sm:pt-8 md:text-5xl"
      >
        Керамические катализаторы
      </h1>

      <h3 className="pt-2 text-center text-xl font-light md:text-2xl">
        Цены на керамические катализаторы
      </h3>

      <div className="mt-6 grid grid-cols-1 space-y-2 sm:grid-cols-3 sm:gap-x-6 sm:space-y-0">
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link
                  to="/"
                  className="text-base font-medium text-gray-500 hover:text-gray-700"
                >
                  Главная
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to="/keramicheskie-katalizatoryi"
                  className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                >
                  Керамические катализаторы
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <div
        id="ceramic-list"
        className="grid grid-cols-1 gap-x-3 gap-y-6 pt-4 lg:grid-cols-3"
      >
        {data.map((car, index) => (
          <PriceCard
            key={`price-card-${index}`}
            link={car.link}
            name={car.name}
            year={car.year}
            img={car.img}
            price={car.price}
            date={car.date}
          />
        ))}
        {children}
      </div>
    </section>
  )
}

export default CatalogCeramic
